import React from 'react'
import { useLocation, useNavigate, Link } from 'react-router-dom'
import downloadResume from '../img/Cody_Wingert_Resume.pdf'

function Navigation() {
    const navigate = useNavigate()
    const location = useLocation()
    const { pathname } = location
    const pathH = pathname === '/'
    const pathF = pathname === '/firewall'
    const pathA = pathname === '/about'

    return (
        <nav
            className={`flex-r justify-content-center ${
                pathH !== '/' ? 'bck-black' : ''
            }`}>
            <div className="flex-r align-items-center justify-content-center nav">
                <div
                    className={`flex-1 ${
                        pathH || pathF ? 'd-none' : 'flex-r'
                    }`}>
                    <button
                        onClick={() => navigate(-1)}
                        className="flex-c pog hover btn-r"
                        title="Go Back">
                        <i className="fa-solid fa-circle-left font-md"></i>
                    </button>
                </div>
                <div className="flex-r flex-2 align-items-center justify-content-center">
                    <a
                        className="flex-c pog"
                        href="https://www.linkedin.com/in/cody-wingert"
                        target="_blank"
                        rel="noopener noreferrer"
                        title="LinkedIn Profile">
                        <i className="fa-brands fa-linkedin-in font-lg"></i>
                    </a>
                    {pathA ? (
                        <button
                            onClick={() => navigate('/')}
                            className="flex-c pog hover btn-r"
                            title="Return Home">
                            <i className="fa-solid fa-home font-md"></i>
                        </button>
                    ) : (
                        <Link
                            className="p-relative mx-2"
                            to="/about"
                            title="About Me">
                            <div className="flex-c profile-img-wrap">
                                <div className="profile-img" />
                            </div>
                            <div className="spin-me" />
                        </Link>
                    )}

                    <a
                        className="flex-c pog"
                        href={downloadResume}
                        target="_blank"
                        rel="noopener noreferrer"
                        title="View Resume">
                        <i className="fa-solid fa-file-pdf font-lg pl-1"></i>
                    </a>
                </div>
                <div
                    className={`flex-1 ${pathH || pathF ? 'd-none' : 'flex-r'}`}
                />
            </div>
        </nav>
    )
}

export default Navigation
