import React from 'react'
import { useDispatch } from 'react-redux'
import { modalView } from '../../config/slice/modal.slice'

export function WidgetModal({ showModal, title, body, icon }) {
    const dispatch = useDispatch()

    return (
        <div
            className="modal-background"
            role="dialog"
            onClick={() => {
                dispatch(modalView(false))
                showModal(false)
            }}>
            <div
                className="col-sm-8 col-md-6 col-lg-4"
                role="document"
                onClick={e => e.stopPropagation()}>
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="flex-c flex-1" />
                        <div className="flex-c flex-2 align-items-center">
                            <i className={`white ${icon}`}></i>
                            <h5 className="modal-title">{title}</h5>
                        </div>
                        <div className="flex-c flex-1 align-items-end">
                            <i
                                className="fa-solid fa-xmark gray-800"
                                onClick={() => {
                                    dispatch(modalView(false))
                                    showModal(false)
                                }}
                                title="Close Modal"></i>
                        </div>
                    </div>
                    <div className="modal-body mb-3 mt-2">{body}</div>
                    <div className="flex-c text-c modal-footer"></div>
                </div>
            </div>
        </div>
    )
}
