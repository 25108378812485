import React, { useState } from 'react'

function Footer() {
    const [isCookies, setCookies] = useState(true)
    const toggleclassName = () => {
        setCookies(!isCookies)
    }

    const scroll = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        })
    }

    const thisYear = new Date().getFullYear()

    return (
        <div className="flex-c justify-content-center bck-black">
            <footer className="flex-c justify-content-center bck-green">
                <div className="gun-wrap">
                    <div
                        className="gun"
                        alt="Scroll to Top"
                        title="Scroll to Top"
                        onClick={scroll}
                    />
                </div>
                <div className="flex-c align-items-center justify-content-center">
                    <span className="font-sm black text-c fw-700">
                        That's it, that's the end, roll the credits: Copyright ©{' '}
                        {thisYear} Cody Wingert. All Rights Reserved.
                    </span>
                </div>
            </footer>
        </div>
    )
}

export default Footer
