import { configureStore } from '@reduxjs/toolkit'
import authReducer from './config/slice/auth.slice'
import modalReducer from './config/slice/modal.slice'

const store = configureStore({
    reducer: {
        auth: authReducer,
        modal: modalReducer
    }
})

export default store
