import React from 'react'

function About() {
    return (
        <div className="flex-c bck-white pb-5">
            <div className="flex-c container nav-offset mb-5">
                <div className="flex-c row mb-5">
                    <div className="flex-c col-12">
                        <h1 className="mb-0">About Me</h1>
                        <span className="font-md fw-700 mt-5 mb-3">
                            The Skinny
                        </span>
                        <p className="mb-5">
                            Hello, I'm Cody! I'm a UX/UI Designer with
                            over 12 years of experience designing software
                            products. I am experienced in B2B and B2C
                            engagements. I have been employed as a contractor,
                            consultant and full/part time employee. I have lead
                            design & software teams for more than 5 years.
                        </p>

                        <span className="font-md fw-700 mb-3">
                            Specifically
                        </span>
                        <p className="mb-5">
                            I have successfully conducted design sprints for
                            multiple organizations. I also have a successful
                            track record as an agile coach. In addition to my
                            design and process skills, I am also a self taught
                            front-end developer with a bit of experience in
                            back-end architecture and data modeling; this is
                            greatly beneficial when communicating with engineers
                            and making tech heavy design decisions.
                        </p>

                        <span className="font-md fw-700 mb-3">Approach</span>
                        <p className="mb-2">
                            Learn, absorb as much applicable information as
                            possible and share that knowledge with your team.
                        </p>
                        <p className="mb-2">
                            Collaborate, identify the problem and postulate a
                            solution.
                        </p>
                        <p className="mb-2">
                            Proof your solution: craft and test a prototype to
                            gauge efficacy.
                        </p>
                        <p className="mb-2">
                            Iterate accordingly: proceed to implementation or
                            continue testing.
                        </p>
                        <p className="mb-5">
                            Rinse and repeat, software is never finished.
                        </p>

                        <span className="font-md fw-700 mb-3">Values</span>
                        <p className="mb-2">
                            Effectively execute from beginning to end.
                        </p>
                        <p className="mb-2">
                            Leaders should not consort with the trench, but
                            should always be ready to grab a shovel.
                        </p>
                        <p className="mb-2">
                            Fearlessly ask questions, rock the boat.
                        </p>
                        <p className="mb-2">
                            Effective efficiency &amp; success are direclty
                            correlated.
                        </p>
                        <p className="mb-5">
                            Ease is unfulfilling, climb the mountain.
                        </p>

                        <span className="font-md fw-700 mb-3">Lifestyle</span>
                        <p className="mb-2">
                            I live at the beach, in beautiful Wilmington, NC,
                            with my wife and two small children. In my spare
                            time, I enjoy surf fishing, long distance running
                            and a good burger/brew. I continue to burn the
                            midnight oil on a company I founded a few years ago,
                            Cardeon, which is dedicated to mental wellness.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About
