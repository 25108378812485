import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
    BrowserRouter as Router,
    Routes,
    Route,
    useNavigate
} from 'react-router-dom'
import {
    Firewall,
    Navigation,
    Footer,
    Home,
    About,
    Case1,
    Case2,
    Case3,
    Case4,
    NotFound
} from './components'
import { userPass } from './config/slice/auth.slice'
import ScrollToTop from './config/helpers/scrollToTop'

function Wrapper({ children }) {
    const navigate = useNavigate()
    const auth = useSelector(state => state.auth.value)

    useEffect(() => {
        if (!auth) {
            navigate('/firewall')
        }
    }, [userPass, auth])

    return <>{children}</>
}

function App() {
    const modal = useSelector(state => state.modal.value)
    const [scroll, setScroll] = useState(false)

    useEffect(() => {
        window.addEventListener('scroll', () => {
            setScroll(window.scrollY > 50)
        })
    }, [modal])

    return (
        <div className={`app ${scroll ? 'scroll' : ''} ${modal ? 'stop' : ''}`}>
            <Router>
                <ScrollToTop />
                <Navigation />
                <Wrapper>
                    <Routes>
                        <Route path="/firewall" element={<Firewall />} />
                        <Route path="/" element={<Home />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/case-1" element={<Case1 />} />
                        <Route path="/case-2" element={<Case2 />} />
                        <Route path="/case-3" element={<Case3 />} />
                        <Route path="/case-4" element={<Case4 />} />
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </Wrapper>
                <Footer />
            </Router>
        </div>
    )
}

export default App
