import React from 'react'
import case1 from '../img/case-2/case2.1-min.png'
import case2 from '../img/case-2/case2.2-min.png'
import case3 from '../img/case-2/case2.3-min.png'
import case4 from '../img/case-2/case2.4-min.png'
import case5 from '../img/case-2/case2.5-min.png'
import case6 from '../img/case-2/case2.6-min.png'

function Case2() {
    return (
        <div className="flex-c bck-white pb-5">
            <div className="flex-c container nav-offset mb-5">
                <div className="flex-c row mb-5">
                    <div className="flex-c col-12">
                        <h1 className="mb-0">Regions Bank</h1>
                        <p>
                            Preapproval credit card application pairing
                            qualified users with available & relevant cards.
                        </p>
                    </div>
                </div>
                <div className="flex-c flex-md-r flex-wrap row mb-5">
                    <div className="flex-c col-12 col-lg-6 col-xl-3 mb-4 mb-xl-0">
                        <span className="font-md fw-700">Role</span>
                        <p>UX/UI Designer</p>
                    </div>
                    <div className="flex-c col-12 col-lg-6 col-xl-3 mb-4 mb-xl-0">
                        <span className="font-md fw-700">Team</span>
                        <p>
                            Project manager, Product Manager, Developer, Content
                            Strategist, UX Researcher
                        </p>
                    </div>
                    <div className="flex-c col-12 col-lg-6 col-xl-3 mb-4 mb-xl-0">
                        <span className="font-md fw-700">Tools</span>
                        <p>Figma, Adobe CC, JIRA, Atlassian</p>
                    </div>
                    <div className="flex-c col-12 col-lg-6 col-xl-3 mb-4 mb-xl-0">
                        <span className="font-md fw-700">Timeline</span>
                        <p>03/23 - 06/23</p>
                    </div>
                </div>
                <div className="flex-c row mb-5">
                    <div className="flex-c col-12">
                        <span className="font-xl fw-700 mb-2">
                            Understanding the Problem
                        </span>
                        <p>
                            There were two core problems in this project. The
                            fist problem was the UI was very dated and needed to
                            be updated to our modern design system UX/UI. The
                            second problem was specifically related to the cards
                            screen. The cards screen is intended to outline
                            &amp; highlight each pre-approved card to the user
                            to help them quickly determine which card is most
                            ideal for them. The existing design had a high
                            bounce rate and users tended to spend too much time
                            on this screen without proceeding past this point in
                            the product flow.
                        </p>
                    </div>
                </div>
                <div className="flex-c row mb-5">
                    <div className="flex-c col-12">
                        <span className="font-xl fw-700 mb-2">
                            The Solution
                        </span>
                        <p>
                            Update the entire pre-approval credit card flow to
                            adopt our modern design system UX and UI.
                            Additionally, create custom designs for the card
                            selection screen to remedy existing issues and align
                            this new design with the existing design system UI.
                        </p>
                    </div>
                </div>
                <div className="flex-c row mb-5">
                    <div className="flex-c col-12">
                        <span className="font-xl fw-700 mb-2">The Process</span>
                        <p className="mb-3">
                            While the UI is largely pre-defined by the design
                            system, the cards screen still needed a custom
                            approach as it is a unique product feature.
                        </p>
                        <ul className="mb-3">
                            <li>
                                Collab with a brainstorming session to define
                                goals, issues, solutions, potential components
                                and determine a strategy for implementation
                            </li>
                            <li>
                                With defined components, iterate on wireframes
                                and review with the team for feedback
                            </li>
                            <li>
                                Continue iteration of wireframes per feedback
                                and start introducing UI components from the
                                design system
                            </li>
                            <li>
                                Collab over low-fidelity mocks to obtain more
                                feedback and further gauge efficacy of design
                                approach
                            </li>
                            <li>
                                Additional iterations, polishing and moving to
                                high-fidelity mocks
                            </li>
                            <li>
                                Update the entier flow with the modern design
                                system UI
                            </li>
                            <li>
                                Demo the entire product flow with the new card
                                designs in place for another round of feedback
                            </li>
                            <li>
                                Implement any additional changes per the last
                                round of review and prepare the design assets
                                for handoff to development
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="flex-c row mb-4">
                    <div className="flex-c col-12">
                        <span className="font-xl fw-700">Brainstorming</span>
                        <div className="divider py-2" />
                        <img
                            src={case1}
                            title="Regions Brainstorming"
                            alt="Regions Brainstorming"
                        />
                    </div>
                </div>
                <div className="flex-c row mb-4">
                    <div className="flex-c col-12">
                        <span className="font-xl fw-700">Wireframes</span>
                        <div className="divider py-2" />
                        <img
                            src={case2}
                            title="Regions Wireframes"
                            alt="Regions Wireframes"
                        />
                    </div>
                </div>
                <div className="flex-c row mb-5">
                    <div className="flex-c col-12">
                        <span className="font-xl fw-700 mb-2">
                            What We Learned
                        </span>
                        <p className="mb-3">
                            Most of what we learned came from both product and
                            development. Product provided insight into the actual cards, as nested products, and how best to
                            highlight card features and content. Development
                            provided insight into technical limitations and
                            boundaries to abide by, given the timeline to
                            complete the project. Heavy development upgrades
                            were not in scope for this project.
                        </p>
                        <p className="mb-3">
                            From the design perspective, we validated the most
                            important data points about each card:
                        </p>
                        <ul>
                            <li>
                                Card Limit: most important, many users will
                                avoid a card with a limit that is too low and
                                sometimes when it's too high
                            </li>
                            <li>
                                Card Image: while not widely important, the card
                                image acts as a great header, dividing the card
                                tiles as well as selling the card with visual
                                appeal
                            </li>
                            <li>
                                Intro Offer, Perks or Benefits and annual fees:
                                all equally important, will vary depending on
                                user financial history and specific need for a
                                card
                            </li>
                            <li>
                                Rates and Pricing PDF is required by compliance
                                to provide additional imformation to every user
                                if necessary. This data isn't usually accessed
                                per our analytics, thus we decided to make it
                                small, but still visible on screen.
                            </li>
                            <li>
                                Lastly, an additional Learn More link that pops
                                a modal into view, providing an extra snippet
                                about the card, as well as a link to the PDF to
                                explore all the card details
                            </li>
                            <li>
                                We also A/B tested layouts and determined the
                                cognitive load was too great to stand these card
                                tiles side by side. Conversion rates were better
                                when users were restricted to having a single
                                card in view at a time; we concluded this was
                                due to card detail digestion and comprehension.
                            </li>
                            <li>
                                We were again reminded that lengthy copy is
                                usually over looked and ignored, even when
                                important. Our design approach allowed us to
                                cherry pick the most important items while still
                                allowing the finer details available for those
                                who wanted them
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="flex-c row my-5">
                    <div className="flex-c col-12">
                        <span className="font-xl fw-700 mb-3">
                            Visual Design
                        </span>
                        <img
                            className="mb-5"
                            src={case3}
                            title="Regions Interface"
                            alt="Regions Interface"
                        />
                        <img
                            className="mb-5"
                            src={case4}
                            title="Regions Interface"
                            alt="Regions Interface"
                        />
                        <img
                            className="mb-5"
                            src={case5}
                            title="Regions Interface"
                            alt="Regions Interface"
                        />
                        <img
                            src={case6}
                            title="Regions Interface"
                            alt="Regions Interface"
                        />
                    </div>
                </div>
                <div className="flex-c row mb-5">
                    <div className="flex-c col-12">
                        <span className="font-xl fw-700 mb-2">
                            Results &amp; Insights
                        </span>
                        <p className="mb-3">
                            What we learned from testing was validated when this
                            product update was released to production.
                        </p>
                        <p className="mb-3">
                            The modern design system UI, in addition to the
                            improved card tile design, reduced bounce rates and
                            improved conversion rates.
                        </p>
                        <p className="mb-3">
                            Users reported spending less time on the card
                            screen, but more confident in their card selections
                            and having an overall better understanding of the
                            differences betwen each card.
                        </p>
                        <p>
                            Ultimately, this improvement resulted in more
                            successful pre-approval credit card applications and
                            card selections which in turn nets the bank
                            additional revenue.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Case2
