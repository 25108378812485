import React from 'react'
import case1 from '../img/case-3/case3.1-min.png'
import case2 from '../img/case-3/case3.2-min.png'
import case3 from '../img/case-3/case3.3-min.png'
import case4 from '../img/case-3/case3.4-min.png'
import case5 from '../img/case-3/case3.5-min.png'

function Case3() {
    return (
        <div className="flex-c bck-white pb-5">
            <div className="flex-c container nav-offset mb-5">
                <div className="flex-c row mb-5">
                    <div className="flex-c col-12">
                        <h1 className="mb-0">YouDecide</h1>
                        <p>
                            CMS for performing CRUD operations on voluntary
                            benefits promotions and offers.
                        </p>
                    </div>
                </div>
                <div className="flex-c flex-md-r flex-wrap row mb-5">
                    <div className="flex-c col-12 col-lg-6 col-xl-3 mb-4 mb-xl-0">
                        <span className="font-md fw-700">Role</span>
                        <p>UX/UI Designer</p>
                    </div>
                    <div className="flex-c col-12 col-lg-6 col-xl-3 mb-4 mb-xl-0">
                        <span className="font-md fw-700">Team</span>
                        <p>
                            Product Owner, SCRUM Master, Designer & 3 Developers
                        </p>
                    </div>
                    <div className="flex-c col-12 col-lg-6 col-xl-3 mb-4 mb-xl-0">
                        <span className="font-md fw-700">Tools</span>
                        <p>Sketch, JIRA, Invision, Adobe CC</p>
                    </div>
                    <div className="flex-c col-12 col-lg-6 col-xl-3 mb-4 mb-xl-0">
                        <span className="font-md fw-700">Timeline</span>
                        <p>03/21 - 12/21</p>
                    </div>
                </div>
                <div className="flex-c row mb-5">
                    <div className="flex-c col-12">
                        <span className="font-xl fw-700 mb-2">
                            Understanding the Problem
                        </span>
                        <p className="mb-3">
                            Our in house staff was responsible for updating
                            content multiple times a day for a large number of
                            records. This was an arduous task to begin with,
                            made more complicated by an existing, poorly
                            designed, interface. The tasks were redundant,
                            rigorous and repetitive, but could be solved with a
                            modern UI.
                        </p>
                    </div>
                </div>
                <div className="flex-c row mb-5">
                    <div className="flex-c col-12">
                        <span className="font-xl fw-700 mb-2">
                            The Solution
                        </span>
                        <p>
                            First we decided to keep it simple. Specifically, a
                            simple UI paired with a UX that is both effective
                            and commonplace. The app needed to have the perfect
                            balance of form and function. Once the specific
                            features were outlined, it was a matter of ideation
                            followed by delivery. Interviewing the users and
                            understanding their needs was essential to
                            prioritizing what to deliver and how. Once we had
                            the analytics we needed, the rest was history.
                            Iterations were quick and concise, the UI deck was
                            crafted and the prototypes were shared for an even
                            deeper analysis.
                        </p>
                    </div>
                </div>
                <div className="flex-c row mb-5">
                    <div className="flex-c col-12">
                        <span className="font-xl fw-700 mb-2">The Process</span>
                        <p className="mb-3">
                            The existing application performed so poorly and provided so
                            little value a complete overhaul was required. We
                            started totally from scratch and created a new
                            design system to accommodate the new application UI.
                        </p>
                        <ul>
                            <li>
                                Interview the employees resonsible for updating
                                the content records and outline the greatest
                                pain points in the existing app
                            </li>
                            <li>
                                Document the responsibilities of each employee
                                who updates records to better define goals and
                                needs
                            </li>
                            <li>
                                Outline the core features required for the app
                                to provide value to the team
                            </li>
                            <li>
                                Start by creating new wires from the existing
                                app UI to get an initial test of efficacy
                            </li>
                            <li>
                                Determine if we're on the right track and
                                proceed if we are, otherwise pivot and repeat
                            </li>
                            <li>
                                Select one of the more desired and robust
                                features as a test feature to build the design
                                system around
                            </li>
                            <li>
                                Craft low-fidelty mocks from the initial wires
                                to further define the UI and gauge team feedback
                                on the UX of the chosen product feature
                            </li>
                            <li>
                                Iterate on navigation to determine the degree of
                                scalability that is required to accommodate all
                                app features
                            </li>
                            <li>
                                Iterate with low-fidelty mocks to refine the UI
                                of the app framework
                            </li>
                            <li>
                                Continue interviews and testing on both the
                                approach to the new UI and the UX: specifically
                                how the app will scale and navigate between
                                features
                            </li>
                            <li>
                                Iterate accordingly and begin to finalize the
                                beginning stages of a design system
                            </li>
                            <li>
                                Product a high-fidelity mockup of a potential
                                solution for the primary app feature and
                                navigation
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="flex-c row mb-4">
                    <div className="flex-c col-12">
                        <span className="flex-r font-xl fw-700">
                            User Goals
                        </span>
                        <div className="divider py-2" />
                        <img
                            src={case1}
                            title="YouDecide User Goals"
                            alt="YouDecide User Goals"
                        />
                    </div>
                </div>
                <div className="flex-c row mb-4">
                    <div className="flex-c col-12">
                        <span className="flex-r font-xl fw-700">
                            User Mapping
                        </span>
                        <div className="divider py-2" />
                        <img
                            src={case2}
                            title="YouDecide User Mapping"
                            alt="YouDecideUser Mapping"
                        />
                    </div>
                </div>
                <div className="flex-c row mb-4">
                    <div className="flex-c col-12">
                        <span className="font-xl fw-700">Wireframes</span>
                        <div className="divider py-2" />
                        <img
                            src={case3}
                            title="YouDecide Wireframes"
                            alt="YouDecide Wireframes"
                        />
                    </div>
                </div>
                <div className="flex-c row mb-5">
                    <div className="flex-c col-12">
                        <span className="font-xl fw-700 mb-2">
                            User Interviews
                        </span>
                        <p className="mb-3">
                            As this was an internal tool, a CMS, user interviews
                            were routinely conducted to understand the needs,
                            wants and existing pain points of the existing
                            application.
                        </p>
                        <p className="mb-3">
                            Additional rounds of interviews were conducted every
                            sprint to validate the work from the previous
                            sprint.
                        </p>
                        <p>
                            This greatly helped us determine how best to create
                            a scalable UX and a modern UI that both looked
                            visually appealing, but also allowed our team to
                            complete in house tasks more efficiently.
                        </p>
                    </div>
                </div>
                <div className="flex-c row my-5">
                    <div className="flex-c col-12">
                        <span className="font-xl fw-700">Testing</span>
                        <div className="divider py-2" />
                        <img
                            src={case4}
                            title="YouDecide Testing"
                            alt="YouDecide Testing"
                        />
                    </div>
                </div>
                <div className="flex-c row mb-5">
                    <div className="flex-c col-12">
                        <span className="font-xl fw-700 mb-2">
                            What We Learned
                        </span>
                        <p className="mb-3">
                            Testing was largely carried out via polls, simple
                            A/B testing and interviews that consisted of
                            questionnaires. We were easily able to determine how
                            scalable the app needed to be, specifically how many
                            features the app woudld encompass, as well as the
                            primary goals of each of our users.
                        </p>
                        <p className="mb-3">
                            Some users had very repetitive tasks so we tried our
                            best to locate items in similar spaces to avoid
                            excessive navigation. Other users had lengthy tasks
                            that could take hours or days to complete, thus we
                            thoguht it best to create robust flows with save
                            options and identifiable indicators allowing anyone
                            to return to the task and pickup where a previous
                            employee may have left off.
                        </p>
                        <p className="mb-3">
                            Rarely if ever would the app be used out of office
                            or on hand held devices as this is designed to be an
                            internal CMS.
                        </p>
                        <p>
                            Lastly, there was also a sense of pride within the
                            app. The team generally wanted the app to be snazzy,
                            fun and modern. The previous application was very
                            dated, something easy on the eyes became a top
                            priority for this project.
                        </p>
                    </div>
                </div>
                <div className="flex-c row my-5">
                    <div className="flex-c col-12">
                        <span className="font-xl fw-700">Visual Design</span>
                        <div className="divider py-2" />
                        <img
                            src={case5}
                            title="YouDecide Interface"
                            alt="YouDecide Interface"
                        />
                    </div>
                </div>
                <div className="flex-c row mb-5">
                    <div className="flex-c col-12">
                        <span className="font-xl fw-700 mb-2">
                            Results &amp; Insights
                        </span>
                        <p className="mb-3">
                            Ultimately, we continued iteration and testing, at
                            times with functional prototypes, and concluded the
                            design deck was ready for handoff to development.
                            Following a few test trials in a staging
                            environment, users reported a massive boost in
                            efficiency; this also reduced burn out.
                        </p>
                        <p className="mb-3">
                            Records could now be updated faster and more
                            efficiently to the extent that employees solely
                            dedicatd to record updating could now assist other
                            departments when necessary.
                        </p>
                        <p>
                            This entire design process also helped to educate
                            the entire team on how best implement modern design
                            thinking practices into other corporate applications
                            with the hopes to replicate results.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Case3
