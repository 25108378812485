import React from 'react'

function NotFound() {
    return (
        <div className="flex-c not-found justify-content-center">
            <div className="flex-c container">
                <div className="flex-c row">
                    <div className="flex-c col-12 align-items-center text-c pt-5">
                        <h1 className="mt-4 white">404 - Page Not Found</h1>
                        <p className=" mb-5 mt-1 white">
                            Well that's not good...
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotFound
