import React from 'react'
import case1 from '../img/case-1/case1.1-min.png'
import case2 from '../img/case-1/case1.2-min.png'
import case3 from '../img/case-1/case1.3-min.png'
import case4 from '../img/case-1/case1.4-min.png'
import case5 from '../img/case-1/case1.5-min.png'
import case6 from '../img/case-1/case1.6-min.png'

function Case1() {
    return (
        <div className="flex-c bck-white pb-5">
            <div className="flex-c container nav-offset mb-5">
                <div className="flex-c row mb-5">
                    <div className="flex-c col-12">
                        <h1 className="mb-0">Cardeon</h1>
                        <p>
                            Data-driven wellness coaching tools to help track,
                            scale and modernize client engagements.
                        </p>
                    </div>
                </div>
                <div className="flex-c flex-md-r flex-wrap row mb-5">
                    <div className="flex-c col-12 col-lg-6 col-xl-3 mb-4 mb-xl-0">
                        <span className="font-md fw-700">Role</span>
                        <p>
                            UI Designer, Product Owner, Project Manager,
                            Front-End Developer
                        </p>
                    </div>
                    <div className="flex-c col-12 col-lg-6 col-xl-3 mb-4 mb-xl-0">
                        <span className="font-md fw-700">Team</span>
                        <p>2 additional Developers</p>
                    </div>
                    <div className="flex-c col-12 col-lg-6 col-xl-3 mb-4 mb-xl-0">
                        <span className="font-md fw-700">Tools</span>
                        <p>Sketch, Gitlab, Invision, Adobe CC</p>
                    </div>
                    <div className="flex-c col-12 col-lg-6 col-xl-3 mb-4 mb-xl-0">
                        <span className="font-md fw-700">Timeline</span>
                        <p>11/17 - Present</p>
                    </div>
                </div>
                <div className="flex-c row mb-5">
                    <div className="flex-c col-12">
                        <span className="font-xl fw-700 mb-2">
                            Understanding the Problem
                        </span>
                        <p className="mb-3">
                            The problem we tried to solve was how to enable caregivers to provide care outside of office hours. A successful solution would allow the caregiver to scale their services, maintain engagement and provide therapy from afar. As a result, we would need to craft tools specifically designed for caregivers to use and their clients to consume.
                        </p>
                        <p>
                            Given the many personas nested within mental wellness, the experience needed be simplistic yet effective. We had to deliver value quickly and easily, and ultimately, there had to be real value that would positively impact the user. The product had to be easy to use and not take up an exuberant amount of time from the caregiver.
                        </p>
                    </div>
                </div>
                <div className="flex-c row mb-5">
                    <div className="flex-c col-12">
                        <span className="font-xl fw-700 mb-2">
                            The Solution
                        </span>
                        <p>
                            Design and develop an MVP encompassing the applicable app features per feedback across all testing phases. Additional features will be prioritized and added to the product roadmap accordingly. Focus on a native app for both caregivers and clients with our complete MVP features baked in and kickoff a web app to follow.
                        </p>
                    </div>
                </div>
                <div className="flex-c row mb-5">
                    <div className="flex-c col-12">
                        <span className="font-xl fw-700 mb-2">The Process</span>
                        <ul className="mb-3">
                            <li>
                                Research: Interview caregivers (therapists,
                                psychologists, etc.) to test the idea and gauge
                                feedback
                            </li>
                            <li>
                                Craft a few wireframes per the initial feedback
                                of what the app might look like for both a
                                caregiver and client
                            </li>
                            <li>
                                Outline a feature list for our MV: security,
                                authentication and core features
                            </li>
                            <li>
                                Refine low-fidelity mocks and request feedback
                                of a very basic prototype to validate or pivot
                                direction
                            </li>
                            <li>
                                Use our most recent feedback to pivot or refine,
                                producing high-fidelity mocks
                            </li>
                            <li>
                                A final test of a polished prototype for our MVP
                                release
                            </li>
                        </ul>
                        <p>
                            Requirements gathering and documentation were the primary area of focus early on. While design testing and iterations were taking place, back-end architecture and data modeling were also starting to take shape. Most of our back-end APIs were already created and tested prior to handing off our high-fidelity mocks to the front-end dev team. That is to say, we truly worked in an agile environment and avoided an assembly line, waterfall, approach to our process.
                        </p>
                    </div>
                </div>
                <div className="flex-c row mb-4">
                    <div className="flex-c col-12">
                        <span className="font-xl fw-700">
                            User Auth Mapping
                        </span>
                        <div className="divider py-2" />
                        <img
                            src={case1}
                            title="Cardeon User Auth Mapping"
                            alt="Cardeon User Auth Mapping"
                        />
                    </div>
                </div>
                <div className="flex-c row mb-4">
                    <div className="flex-c col-12">
                        <span className="font-xl fw-700">Wireframes</span>
                        <div className="divider py-2" />
                        <img
                            src={case2}
                            title="Cardeon Wireframes"
                            alt="Cardeon Wireframes"
                        />
                    </div>
                </div>
                <div className="flex-c row mb-5">
                    <div className="flex-c col-12">
                        <span className="font-xl fw-700 mb-2">
                            What We Learned
                        </span>
                        <p className="mb-3">
                            The MVP will consist of five primary features: Cards, Teams, Messaging, Profiles and Search. This was determined to be the most effective set of features allowing caregivers to connect with their clients long term, in addition to normal office hours. In addition to these custom core app features, other features like settings, identify and authentication were also included into our MVP.
                        </p>
                        <p className="mb-3">
                            Upon completion of the native app, focus will be directed towards a web app with a specific focus on caregivers. Testing revealed most caregivers will utilize the service at their desktop, in office, while most clients will utilize the service on their mobile devices at home or away from office.
                        </p>
                        <p>
                            Eventually we will scale the web app to match the native app verbatim, but for the time being, and to be as efficient as possible, we will target dedicated devices and their user type with corresponding app features.
                        </p>
                    </div>
                </div>
                <div className="flex-c row my-5">
                    <div className="flex-c col-12">
                        <span className="font-xl fw-700">Visual Design</span>
                        <div className="divider py-2" />
                        <img
                            src={case3}
                            title="Cardeon Interface"
                            alt="Cardeon Interface"
                        />
                        <img
                            src={case4}
                            title="Cardeon Interface"
                            alt="Cardeon Interface"
                        />
                        <img
                            src={case5}
                            title="Cardeon Interface"
                            alt="Cardeon Interface"
                        />
                        <img
                            src={case6}
                            title="Cardeon Interface"
                            alt="Cardeon Interface"
                        />
                    </div>
                </div>
                <div className="flex-c row mb-5">
                    <div className="flex-c col-12">
                        <span className="font-xl fw-700 mb-2">
                            Results &amp; Insights
                        </span>
                        <p className="mb-3">
                            Testing proved most useful when we decided to prioritise the native app, followed by a tailored web app. Clients and caregivers utilized the exact features necessary from our MVP.
                        </p>
                        <p className="mb-3">
                            This early success allowed us to iterate on existing features, fix bugs and start to craft additional features.
                        </p>
                        <p className="mb-3">
                            Continued user testing with prototypes has provided informed insight into how to more efficiently pair features and UI components to improve the overall usability of the app, as well as accessibility.
                        </p>
                        <p>
                            This is an ongoing project, we are continuously gauging and digesting feedback from both clients and caregivers to continue improving both the native and web app.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Case1
