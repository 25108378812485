import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, Link } from 'react-router-dom'
import { modalView } from '../config/slice/modal.slice'
import { WidgetModal } from '../components/Modals/widgetModal'

function Home() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const auth = useSelector(state => state.auth.value)
    const [modalValue, setModalValue] = useState(false)
    const [titleValue, setTitleValue] = useState(false)
    const [bodyValue, setBodyValue] = useState(false)
    const [iconValue, setIconValue] = useState(false)

    useEffect(() => {
        if (auth) {
            navigate('/')
        } else {
            navigate('/firewall')
        }
    }, [])

    return (
        <>
            <div className="flex-c bck-black">
                <div className="flex-c intro mb-lg-5">
                    <div className="flex-c overlay">
                        <div className="flex-c nav-offset container">
                            <div className="flex-c align-items-center row">
                                <div className="flex-c align-items-center justify-content-center col-12 col-md-10 mb-5">
                                    <h1 className="white text-c mb-2 mt-md-5">
                                        Hi, I'm Cody!
                                    </h1>
                                    <span className="white text-c fw-600 mb-md-5 px-3">
                                        I'm a UX/UI Designer with over 12
                                        years of experience designing software
                                        products.
                                    </span>

                                    <div className="flex-c flex-sm-r align-items-center mt-4 mb-5">
                                        <span className="white fw-600 font-sm pb-1 pb-sm-0">
                                            wincod75@gmail.com
                                        </span>
                                        <span className="d-none g-600 d-sm-flex white px-2">
                                            |
                                        </span>
                                        <span className="white font-sm">
                                            Wilmington, NC
                                        </span>
                                    </div>

                                    <div className="flex-r hero-grid">
                                        <div className="box align-items-sm-end justify-content-sm-end">
                                            <div
                                                className="widget"
                                                onClick={() => {
                                                    dispatch(modalView(true))
                                                    setModalValue(true)
                                                    setTitleValue('Release')
                                                    setBodyValue(
                                                        'Develop and initiate a plan for release to your users, restart the process again when necessary.'
                                                    )
                                                    setIconValue(
                                                        'fa-solid fa-code-merge'
                                                    )
                                                }}>
                                                <i className="fa-solid fa-code-merge green"></i>
                                                <span className="fw-600 pt-2">
                                                    Release
                                                </span>
                                            </div>
                                        </div>
                                        <div className="box">
                                            <div
                                                className="widget"
                                                onClick={() => {
                                                    dispatch(modalView(true))
                                                    setModalValue(true)
                                                    setTitleValue('Empathize')
                                                    setBodyValue(
                                                        'Dissect the problem, identify any and all painpoints and sympathize with the needs of the user.'
                                                    )
                                                    setIconValue(
                                                        'fa-regular fa-lightbulb'
                                                    )
                                                }}>
                                                <i className="fa-regular fa-lightbulb green"></i>
                                                <span className="fw-600 pt-2">
                                                    Empathize
                                                </span>
                                            </div>
                                        </div>
                                        <div className="box align-items-sm-start justify-content-sm-end">
                                            <div
                                                className="widget"
                                                onClick={() => {
                                                    dispatch(modalView(true))
                                                    setModalValue(true)
                                                    setTitleValue('Define')
                                                    setBodyValue(
                                                        'Unearth the root cause and any additional attributors or consequences in order to outline an applicable solution.'
                                                    )
                                                    setIconValue(
                                                        'fa-regular fa-file-lines'
                                                    )
                                                }}>
                                                <i className="fa-regular fa-file-lines green"></i>
                                                <span className="fw-600 pt-2">
                                                    Define
                                                </span>
                                            </div>
                                        </div>

                                        <div className="box">
                                            <div
                                                className="widget"
                                                onClick={() => {
                                                    dispatch(modalView(true))
                                                    setModalValue(true)
                                                    setTitleValue('Implement')
                                                    setBodyValue(
                                                        'When initial testing is complete and iterations have been applied, implement your solution for pending release.'
                                                    )
                                                    setIconValue(
                                                        'fa-regular fa-file-code'
                                                    )
                                                }}>
                                                <i className="fa-regular fa-file-code green"></i>
                                                <span className="fw-600 pt-2">
                                                    Implement
                                                </span>
                                            </div>
                                        </div>
                                        <div className="box">
                                            <div className="rick" />
                                        </div>
                                        <div className="box">
                                            <div
                                                className="widget"
                                                onClick={() => {
                                                    dispatch(modalView(true))
                                                    setModalValue(true)
                                                    setTitleValue('Ideate')
                                                    setBodyValue(
                                                        'Brainstorm all potential solutions, the quality of impact and the strategy to implement.'
                                                    )
                                                    setIconValue(
                                                        'fa-regular fa-map'
                                                    )
                                                }}>
                                                <i className="fa-regular fa-map green"></i>
                                                <span className="fw-600 pt-2">
                                                    Ideate
                                                </span>
                                            </div>
                                        </div>

                                        <div className="box align-items-sm-end justify-content-sm-start">
                                            <div
                                                className="widget"
                                                onClick={() => {
                                                    dispatch(modalView(true))
                                                    setModalValue(true)
                                                    setTitleValue('Iterate')
                                                    setBodyValue(
                                                        'Evaluate feedback and apply remedies to any and all flaws in your solution while gauging the impact on overall strategy.'
                                                    )
                                                    setIconValue(
                                                        'fa-solid fa-sliders'
                                                    )
                                                }}>
                                                <i className="fa-solid fa-sliders green"></i>
                                                <span className="fw-600 pt-2">
                                                    Iterate
                                                </span>
                                            </div>
                                        </div>
                                        <div className="box">
                                            <div
                                                className="widget"
                                                onClick={() => {
                                                    dispatch(modalView(true))
                                                    setModalValue(true)
                                                    setTitleValue('Test')
                                                    setBodyValue(
                                                        'Experiment with your solution and evaluate feedback in preparation for potential iterations.'
                                                    )
                                                    setIconValue(
                                                        'fa-solid fa-flask-vial'
                                                    )
                                                }}>
                                                <i className="fa-solid fa-flask-vial green"></i>
                                                <span className="fw-600 pt-2">
                                                    Test
                                                </span>
                                            </div>
                                        </div>
                                        <div className="box align-items-sm-start justify-content-sm-start">
                                            <div
                                                className="widget"
                                                onClick={() => {
                                                    dispatch(modalView(true))
                                                    setModalValue(true)
                                                    setTitleValue('Prototype')
                                                    setBodyValue(
                                                        'Build a test trial for your solution to gauge efficacy allowing for the collection of feedback.'
                                                    )
                                                    setIconValue(
                                                        'fa-solid fa-mask'
                                                    )
                                                }}>
                                                <i className="fa-solid fa-mask green"></i>
                                                <span className="fw-600 pt-2">
                                                    Prototype
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="intro-fade">
                            <div className="flex-c container">
                                <div className="flex-c align-items-center row">
                                    <div className="flex-c flex-lg-r pt-5">
                                        <div className="col-lg-4">
                                            <div className="feature-tile border-blue">
                                                <div className="badge bck-blue-d">
                                                    <i className="fa-solid fa-users-gear blue"></i>
                                                </div>
                                                <h3 className="blue font-xl mb-3">
                                                    Process
                                                </h3>

                                                <div className="flex-r flex-wrap justify-content-center text-c">
                                                    <span className="white fw-600 pr-2 pb-1">
                                                        Agile,
                                                    </span>
                                                    <span className="white fw-600 pr-2 pb-1">
                                                        SCRUM/Kanban,
                                                    </span>
                                                    <span className="white fw-600 pr-2 pb-1">
                                                        Backlog Refinement,
                                                    </span>
                                                    <span className="white fw-600 pr-2 pb-1">
                                                        Sprints,
                                                    </span>
                                                    <span className="white fw-600 pr-2 pb-1">
                                                        Planning,
                                                    </span>
                                                    <span className="white fw-600 pr-2 pb-1">
                                                        Estimates,
                                                    </span>
                                                    <span className="white fw-600 pr-2">
                                                        Learning Loops
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="feat-mr feat-mr-b"></div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="feature-tile border-green">
                                                <div className="badge bck-green-d">
                                                    <i className="fa-solid fa-swatchbook green pl-2"></i>
                                                </div>
                                                <h3 className="green font-xl mb-3">
                                                    Design
                                                </h3>

                                                <div className="flex-r flex-wrap justify-content-center text-c">
                                                    <span className="white fw-600 pr-2 pb-1">
                                                        UX/UI,
                                                    </span>
                                                    <span className="white fw-600 pr-2 pb-1">
                                                        Personas,
                                                    </span>
                                                    <span className="white fw-600 pr-2 pb-1">
                                                        Journeys,
                                                    </span>
                                                    <span className="white fw-600 pr-2 pb-1">
                                                        Wireframes,
                                                    </span>
                                                    <span className="white fw-600 pr-2 pb-1">
                                                        Prototypes,
                                                    </span>
                                                    <span className="white fw-600 pr-2 pb-1">
                                                        Testing,
                                                    </span>
                                                    <span className="white fw-600 pr-2 pb-1">
                                                        Accessibility,
                                                    </span>
                                                    <span className="white fw-600 pr-2">
                                                        Design Systems
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="feat-mr feat-mr-g"></div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="feature-tile border-red">
                                                <div className="badge bck-red-d">
                                                    <i className="fa-solid fa-code-merge red pl-2"></i>
                                                </div>
                                                <h3 className="red font-xl mb-3">
                                                    Develop
                                                </h3>

                                                <div className="flex-r flex-wrap justify-content-center text-c">
                                                    <span className="white fw-600 pr-2 pb-1">
                                                        Front-end,
                                                    </span>
                                                    <span className="white fw-600 pr-2 pb-1">
                                                        Pixel Perfect,
                                                    </span>
                                                    <span className="white fw-600 pr-2 pb-1">
                                                        HTML,
                                                    </span>
                                                    <span className="white fw-600 pr-2 pb-1">
                                                        CSS,
                                                    </span>
                                                    <span className="white fw-600 pr-2 pb-1">
                                                        JavaScript,
                                                    </span>
                                                    <span className="white fw-600 pr-2 pb-1">
                                                        VS Code,
                                                    </span>
                                                    <span className="white fw-600 pr-2 pb-1">
                                                        Web,
                                                    </span>
                                                    <span className="white fw-600 pr-2 pb-1">
                                                        Native,
                                                    </span>
                                                    <span className="white fw-600">
                                                        Mobile First
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="feat-mr feat-mr-r"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex-c container my-5">
                    <div className="flex-c flex-md-r flex-wrap row">
                        <div className="flex-c case pad-offset width-48 my-5">
                            <div
                                className="frame frame-1"
                                title="Cardeon"
                                alt="Cardeon"
                            />
                            <h6 className="white font-xl mt-3 mb-1">Cardeon</h6>

                            <div className="flex-r flex-wrap align-items-center">
                                <span className="green font-sm fw-600">UX</span>
                                <span className="g-600 d-sm-flex white px-2">
                                    |
                                </span>
                                <span className="green font-sm fw-600">UI</span>
                                <span className="g-600 d-sm-flex white px-2">
                                    |
                                </span>
                                <span className="green font-sm fw-600">
                                    Research
                                </span>
                                <span className="g-600 d-sm-flex white px-2">
                                    |
                                </span>
                                <span className="green font-sm fw-600">
                                    Testing
                                </span>
                                <span className="g-600 d-sm-flex white px-2">
                                    |
                                </span>
                                <span className="green font-sm fw-600">
                                    Development
                                </span>
                                <span className="g-600 d-sm-flex white px-2">
                                    |
                                </span>
                                <span className="green font-sm fw-600">
                                    Project Management
                                </span>
                                <span className="g-600 d-sm-flex white px-2">
                                    |
                                </span>
                                <span className="green font-sm fw-600">
                                    B2C
                                </span>
                            </div>

                            <span className="white fw-500 mt-3">
                                Data-driven wellness coaching tools to help
                                track, scale and modernize client engagements.
                            </span>
                            <div className="flex-r mt-4-5">
                                <Link
                                    className="btn btn-p mr-3"
                                    to="/case-1"
                                    title="View Cardeon">
                                    View Project
                                </Link>
                                <a
                                    className="btn btn-s"
                                    href="https://www.cardeon.io/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    title="Explore Cardeon">
                                    Explore
                                </a>
                            </div>
                        </div>
                        <div className="flex-c width-4" />
                        <div className="flex-c case pad-offset width-48 my-5">
                            <div
                                className="frame frame-2"
                                title="Regions"
                                alt="Regions"
                            />
                            <h6 className="white font-xl mt-3 mb-1">
                                Regions Bank
                            </h6>

                            <div className="flex-r flex-wrap align-items-center">
                                <span className="green font-sm fw-600">UX</span>
                                <span className="g-600 d-sm-flex white px-2">
                                    |
                                </span>
                                <span className="green font-sm fw-600">UI</span>
                                <span className="g-600 d-sm-flex white px-2">
                                    |
                                </span>
                                <span className="green font-sm fw-600">
                                    Data Analysis
                                </span>
                                <span className="g-600 d-sm-flex white px-2">
                                    |
                                </span>
                                <span className="green font-sm fw-600">
                                    Prototyping
                                </span>
                                <span className="g-600 d-sm-flex white px-2">
                                    |
                                </span>
                                <span className="green font-sm fw-600">
                                    Design System
                                </span>
                                <span className="g-600 d-sm-flex white px-2">
                                    |
                                </span>
                                <span className="green font-sm fw-600">
                                    B2C
                                </span>
                            </div>

                            <span className="white fw-500 mt-3">
                                Preapproval credit card application pairing
                                qualified users with available & relevant cards.
                            </span>
                            <div className="flex-r mt-4-5">
                                <Link
                                    className="btn btn-p mr-3"
                                    to="/case-2"
                                    title="View Regions">
                                    View Project
                                </Link>
                                <a
                                    className="btn btn-s"
                                    href="https://apply.regions.com/creditcards/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    title="Explore Regions">
                                    Explore
                                </a>
                            </div>
                        </div>

                        <div className="flex-c case pad-offset width-48 my-5">
                            <div
                                className="frame frame-3"
                                title="YouDecide"
                                alt="YouDecide"
                            />
                            <h6 className="white font-xl mt-3 mb-1">
                                YouDecide
                            </h6>

                            <div className="flex-r flex-wrap align-items-center">
                                <span className="green font-sm fw-600">UX</span>
                                <span className="g-600 d-sm-flex white px-2">
                                    |
                                </span>
                                <span className="green font-sm fw-600">UI</span>
                                <span className="g-600 d-sm-flex white px-2">
                                    |
                                </span>
                                <span className="green font-sm fw-600">
                                    Testing
                                </span>
                                <span className="g-600 d-sm-flex white px-2">
                                    |
                                </span>
                                <span className="green font-sm fw-600">
                                    Data Analysis
                                </span>
                                <span className="g-600 d-sm-flex white px-2">
                                    |
                                </span>
                                <span className="green font-sm fw-600">
                                    User Research
                                </span>
                            </div>

                            <span className="white fw-500 mt-3">
                                CMS for performing CRUD operations on voluntary
                                benefits promotions and offers.
                            </span>
                            <div className="flex-r mt-4-5">
                                <Link
                                    className="btn btn-p mr-3"
                                    to="/case-3"
                                    title="View YouDecide">
                                    View Project
                                </Link>
                                <a
                                    className="btn btn-s"
                                    href="https://info.youdecide.com/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    title="Explore YouDecide">
                                    Explore
                                </a>
                            </div>
                        </div>

                        <div className="flex-c width-4" />

                        <div className="flex-c case pad-offset width-48 my-5">
                            <div
                                className="frame frame-4"
                                title="Prototype"
                                alt="Prototype"
                            />
                            <h6 className="white font-xl mt-3 mb-1">
                                Prototype
                            </h6>

                            <div className="flex-r flex-wrap align-items-center">
                                <span className="green font-sm fw-600">UX</span>
                                <span className="g-600 d-sm-flex white px-2">
                                    |
                                </span>
                                <span className="green font-sm fw-600">UI</span>
                                <span className="g-600 d-sm-flex white px-2">
                                    |
                                </span>
                                <span className="green font-sm fw-600">
                                    Research
                                </span>
                                <span className="g-600 d-sm-flex white px-2">
                                    |
                                </span>
                                <span className="green font-sm fw-600">
                                    Testing
                                </span>
                                <span className="g-600 d-sm-flex white px-2">
                                    |
                                </span>
                                <span className="green font-sm fw-600">
                                    Prototyping
                                </span>
                                <span className="g-600 d-sm-flex white px-2">
                                    |
                                </span>
                                <span className="green font-sm fw-600">
                                    B2C
                                </span>
                            </div>

                            <span className="white fw-500 mt-3">
                                A prototype steps counter Designed to help raise
                                funds and awareness for various causes.
                            </span>
                            <div className="flex-r mt-4-5">
                                <Link
                                    className="btn btn-p mr-3"
                                    to="/case-4"
                                    title="View Prototype">
                                    View Project
                                </Link>
                                <a
                                    className="btn btn-s"
                                    href="https://cancerflight.invisionapp.com/console/share/3P36C94Z2Q/830029348"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    title="Explore Project">
                                    Explore
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex-c container my-5">
                    <div className="flex-c flex-md-r flex-wrap row">
                        <div className="flex-r skill pad-offset width-48">
                            <div className="icon-wrap">
                                <i className="fa-solid fa-arrows-spin black z-res"></i>
                            </div>
                            <div className="flex-c flex-1">
                                <span className="white fw-700 font-lg">
                                    Agile
                                </span>
                                <div className="flex-c stat bck-blue-d">
                                    <div
                                        className="flex-c bck-blue"
                                        style={{ width: '100%' }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex-c width-4" />
                        <div className="flex-r skill pad-offset width-48">
                            <div className="icon-wrap">
                                <i className="fa-solid fa-table-columns black z-res"></i>
                            </div>
                            <div className="flex-c flex-1">
                                <span className="white fw-700 font-lg">
                                    SCRUM/Kanban
                                </span>
                                <div className="flex-c stat bck-blue-d">
                                    <div
                                        className="flex-c bck-blue"
                                        style={{ width: '90%' }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex-r skill pad-offset width-48">
                            <div className="icon-wrap">
                                <i className="fa-solid fa-signs-post black z-res"></i>
                            </div>
                            <div className="flex-c flex-1">
                                <span className="white fw-700 font-lg">
                                    Product Strategy
                                </span>
                                <div className="flex-c stat bck-blue-d">
                                    <div
                                        className="flex-c bck-blue"
                                        style={{ width: '90%' }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex-c width-4" />
                        <div className="flex-r skill pad-offset width-48">
                            <div className="icon-wrap">
                                <i className="fa-solid fa-people-roof black z-res"></i>
                            </div>
                            <div className="flex-c flex-1">
                                <span className="white fw-700 font-lg">
                                    Project Management
                                </span>
                                <div className="flex-c stat bck-blue-d">
                                    <div
                                        className="flex-c bck-blue"
                                        style={{ width: '100%' }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex-r skill pad-offset width-48">
                            <div className="icon-wrap">
                                <i className="fa-solid fa-user-ninja black z-res"></i>
                            </div>
                            <div className="flex-c flex-1">
                                <span className="white fw-700 font-lg">
                                    Team Leadership
                                </span>
                                <div className="flex-c stat bck-blue-d">
                                    <div
                                        className="flex-c bck-blue"
                                        style={{ width: '100%' }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex-c width-4" />
                        <div className="flex-r skill pad-offset width-48">
                            <div className="icon-wrap">
                                <i className="fa-solid fa-person-running black z-res"></i>
                            </div>
                            <div className="flex-c flex-1">
                                <span className="white fw-700 font-lg">
                                    Sprints
                                </span>
                                <div className="flex-c stat bck-blue-d">
                                    <div
                                        className="flex-c bck-blue"
                                        style={{ width: '100%' }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex-r skill pad-offset width-48">
                            <div className="icon-wrap">
                                <i className="fa-brands fa-gitlab black z-res"></i>
                            </div>
                            <div className="flex-c flex-1">
                                <span className="white fw-700 font-lg">
                                    Gitlab
                                </span>
                                <div className="flex-c stat bck-blue-d">
                                    <div
                                        className="flex-c bck-blue"
                                        style={{ width: '90%' }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex-c width-4" />
                        <div className="flex-r skill pad-offset width-48">
                            <div className="icon-wrap">
                                <i className="fa-brands fa-atlassian black z-res"></i>
                            </div>
                            <div className="flex-c flex-1">
                                <span className="white fw-700 font-lg">
                                    Atlassian
                                </span>
                                <div className="flex-c stat bck-blue-d">
                                    <div
                                        className="flex-c bck-blue"
                                        style={{ width: '80%' }}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="flex-r skill pad-offset width-48">
                            <div className="icon-wrap">
                                <i className="fa-solid fa-route black z-res"></i>
                            </div>
                            <div className="flex-c flex-1">
                                <span className="white fw-700 font-lg">UX</span>
                                <div className="flex-c stat bck-green-d">
                                    <div
                                        className="flex-c bck-green"
                                        style={{ width: '100%' }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex-c width-4" />
                        <div className="flex-r skill pad-offset width-48">
                            <div className="icon-wrap">
                                <i className="fa-solid fa-pen-ruler black z-res"></i>
                            </div>
                            <div className="flex-c flex-1">
                                <span className="white fw-700 font-lg">UI</span>
                                <div className="flex-c stat bck-green-d">
                                    <div
                                        className="flex-c bck-green"
                                        style={{ width: '100%' }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex-r skill pad-offset width-48">
                            <div className="icon-wrap">
                                <i className="fa-solid fa-arrows-to-circle black z-res"></i>
                            </div>
                            <div className="flex-c flex-1">
                                <span className="white fw-700 font-lg">
                                    Interaction Design
                                </span>
                                <div className="flex-c stat bck-green-d">
                                    <div
                                        className="flex-c bck-green"
                                        style={{ width: '100%' }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex-c width-4" />
                        <div className="flex-r skill pad-offset width-48">
                            <div className="icon-wrap">
                                <i className="fa-solid fa-users-viewfinder black z-res"></i>
                            </div>
                            <div className="flex-c flex-1">
                                <span className="white fw-700 font-lg">
                                    User-centered Design
                                </span>
                                <div className="flex-c stat bck-green-d">
                                    <div
                                        className="flex-c bck-green"
                                        style={{ width: '100%' }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex-r skill pad-offset width-48">
                            <div className="icon-wrap">
                                <i className="fa-solid fa-draw-polygon black z-res"></i>
                            </div>
                            <div className="flex-c flex-1">
                                <span className="white fw-700 font-lg">
                                    Wireframing
                                </span>
                                <div className="flex-c stat bck-green-d">
                                    <div
                                        className="flex-c bck-green"
                                        style={{ width: '100%' }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex-c width-4" />
                        <div className="flex-r skill pad-offset width-48">
                            <div className="icon-wrap">
                                <i className="fa-solid fa-mask black z-res"></i>
                            </div>
                            <div className="flex-c flex-1">
                                <span className="white fw-700 font-lg">
                                    Prototyping
                                </span>
                                <div className="flex-c stat bck-green-d">
                                    <div
                                        className="flex-c bck-green"
                                        style={{ width: '100%' }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex-r skill pad-offset width-48">
                            <div className="icon-wrap">
                                <i className="fa-solid fa-cubes black z-res"></i>
                            </div>
                            <div className="flex-c flex-1">
                                <span className="white fw-700 font-lg">
                                    Design Systems
                                </span>
                                <div className="flex-c stat bck-green-d">
                                    <div
                                        className="flex-c bck-green"
                                        style={{ width: '100%' }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex-c width-4" />
                        <div className="flex-r skill pad-offset width-48">
                            <div className="icon-wrap">
                                <i className="fa-solid fa-note-sticky black z-res"></i>
                            </div>
                            <div className="flex-c flex-1">
                                <span className="white fw-700 font-lg">
                                    Design Sprints
                                </span>
                                <div className="flex-c stat bck-green-d">
                                    <div
                                        className="flex-c bck-green"
                                        style={{ width: '100%' }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex-r skill pad-offset width-48">
                            <div className="icon-wrap">
                                <i className="fa-solid fa-chalkboard-user black z-res"></i>
                            </div>
                            <div className="flex-c flex-1">
                                <span className="white fw-700 font-lg">
                                    User Research
                                </span>
                                <div className="flex-c stat bck-green-d">
                                    <div
                                        className="flex-c bck-green"
                                        style={{ width: '85%' }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex-c width-4" />
                        <div className="flex-r skill pad-offset width-48">
                            <div className="icon-wrap">
                                <i className="fa-brands fa-sketch black z-res pb-3"></i>
                                <i className="fa-brands fa-figma black z-res pt-3"></i>
                            </div>
                            <div className="flex-c flex-1">
                                <span className="white fw-700 font-lg">
                                    Sketch/Figma
                                </span>
                                <div className="flex-c stat bck-green-d">
                                    <div
                                        className="flex-c bck-green"
                                        style={{ width: '100%' }}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="flex-r skill pad-offset width-48">
                            <div className="icon-wrap">
                                <i className="fa-brands fa-html5 black z-res"></i>
                            </div>
                            <div className="flex-c flex-1">
                                <span className="white fw-700 font-lg">
                                    HTML
                                </span>
                                <div className="flex-c stat bck-red-d">
                                    <div
                                        className="flex-c bck-red"
                                        style={{ width: '100%' }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex-c width-4" />
                        <div className="flex-r skill pad-offset width-48">
                            <div className="icon-wrap">
                                <i className="fa-brands fa-css3 black z-res"></i>
                            </div>
                            <div className="flex-c flex-1">
                                <span className="white fw-700 font-lg">
                                    CSS
                                </span>
                                <div className="flex-c stat bck-red-d">
                                    <div
                                        className="flex-c bck-red"
                                        style={{ width: '100%' }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex-r skill pad-offset width-48">
                            <div className="icon-wrap">
                                <i className="fa-brands fa-bootstrap black z-res"></i>
                            </div>
                            <div className="flex-c flex-1">
                                <span className="white fw-700 font-lg">
                                    Bootstrap
                                </span>
                                <div className="flex-c stat bck-red-d">
                                    <div
                                        className="flex-c bck-red"
                                        style={{ width: '100%' }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex-c width-4" />
                        <div className="flex-r skill pad-offset width-48">
                            <div className="icon-wrap">
                                <i className="fa-brands fa-grunt black z-res"></i>
                            </div>
                            <div className="flex-c flex-1">
                                <span className="white fw-700 font-lg">
                                    Grunt
                                </span>
                                <div className="flex-c stat bck-red-d">
                                    <div
                                        className="flex-c bck-red"
                                        style={{ width: '90%' }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex-r skill pad-offset width-48">
                            <div className="icon-wrap">
                                <i className="fa-brands fa-react black z-res"></i>
                            </div>
                            <div className="flex-c flex-1">
                                <span className="white fw-700 font-lg">
                                    React
                                </span>
                                <div className="flex-c stat bck-red-d">
                                    <div
                                        className="flex-c bck-red"
                                        style={{ width: '75%' }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex-c width-4" />
                        <div className="flex-r skill pad-offset width-48">
                            <div className="icon-wrap">
                                <i className="fa-brands fa-node-js black z-res"></i>
                            </div>
                            <div className="flex-c flex-1">
                                <span className="white fw-700 font-lg">
                                    Node
                                </span>
                                <div className="flex-c stat bck-red-d">
                                    <div
                                        className="flex-c bck-red"
                                        style={{ width: '60%' }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex-r skill pad-offset width-48">
                            <div className="icon-wrap">
                                <i className="fa-solid fa-terminal black z-res"></i>
                            </div>
                            <div className="flex-c flex-1">
                                <span className="white fw-700 font-lg">
                                    VS Code
                                </span>
                                <div className="flex-c stat bck-red-d">
                                    <div
                                        className="flex-c bck-red"
                                        style={{ width: '90%' }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex-c width-4" />
                        <div className="flex-r skill pad-offset width-48">
                            <div className="icon-wrap">
                                <i className="fa-brands fa-github black z-res"></i>
                            </div>
                            <div className="flex-c flex-1">
                                <span className="white fw-700 font-lg">
                                    Github
                                </span>
                                <div className="flex-c stat bck-red-d">
                                    <div
                                        className="flex-c bck-red"
                                        style={{ width: '90%' }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex-c container my-5">
                    <div className="flex-c flex-md-r row mt-5">
                        <div className="flex-c col-12 mb-5">
                            <div className="flex-c flex-lg-r job">
                                <div className="flex-c flex-1 text-c text-md-l align-items-center align-items-lg-start">
                                    <span className="white font-lg mb-1 mb-lg-0">
                                        Principal UX/UI Designer
                                    </span>
                                    <span className="white fw-700 font-sm pt-1">
                                        Regions Bank
                                    </span>
                                </div>
                                <div className="flex-r mr-lg-5 my-4 my-lg-0">
                                    <i className="fa-solid fa-users-gear font-lg g-700"></i>
                                    <i className="fa-solid fa-swatchbook font-lg green mx-4"></i>
                                    <i className="fa-solid fa-code-merge font-lg g-700"></i>
                                </div>
                                <div className="green font-sm ml-lg-5 mt-1">
                                    03/22 - Present
                                </div>
                            </div>
                            <div className="flex-c flex-lg-r job">
                                <div className="flex-c flex-1 text-c text-md-l align-items-center align-items-lg-start">
                                    <span className="white font-lg mb-1 mb-lg-0">
                                        Founder
                                    </span>
                                    <span className="white fw-700 font-sm pt-1">
                                        Cardeon
                                    </span>
                                </div>
                                <div className="flex-r mr-lg-5 my-4 my-lg-0">
                                    <i className="fa-solid fa-users-gear font-lg blue"></i>
                                    <i className="fa-solid fa-swatchbook font-lg green mx-4"></i>
                                    <i className="fa-solid fa-code-merge font-lg red"></i>
                                </div>
                                <div className="green font-sm ml-lg-5 mt-1">
                                    1/17 - Present
                                </div>
                            </div>
                            <div className="flex-c flex-lg-r job">
                                <div className="flex-c flex-1 text-c text-md-l align-items-center align-items-lg-start">
                                    <span className="white font-lg mb-1 mb-lg-0">
                                        Manager UX/UI
                                    </span>
                                    <span className="white fw-700 font-sm pt-1">
                                        Development Operations Security (DOS)
                                    </span>
                                </div>
                                <div className="flex-r mr-lg-5 my-4 my-lg-0">
                                    <i className="fa-solid fa-users-gear font-lg blue"></i>
                                    <i className="fa-solid fa-swatchbook font-lg green mx-4"></i>
                                    <i className="fa-solid fa-code-merge font-lg red"></i>
                                </div>
                                <div className="green font-sm ml-lg-5 mt-1">
                                    10/18 - 12/21
                                </div>
                            </div>
                            <div className="flex-c flex-lg-r job">
                                <div className="flex-c flex-1 text-c text-md-l align-items-center align-items-lg-start">
                                    <span className="white font-lg mb-1 mb-lg-0">
                                        Staff UX/UI Designer & Developer
                                    </span>
                                    <span className="white fw-700 font-sm pt-1">
                                        Live Oak Bank
                                    </span>
                                </div>
                                <div className="flex-r mr-lg-5 my-4 my-lg-0">
                                    <i className="fa-solid fa-users-gear font-lg blue"></i>
                                    <i className="fa-solid fa-swatchbook font-lg green mx-4"></i>
                                    <i className="fa-solid fa-code-merge font-lg red"></i>
                                </div>
                                <div className="green font-sm ml-lg-5 mt-1">
                                    02/17 - 10/18
                                </div>
                            </div>
                            <div className="flex-c flex-lg-r job">
                                <div className="flex-c flex-1 text-c text-md-l align-items-center align-items-lg-start">
                                    <span className="white font-lg mb-1 mb-lg-0">
                                        Staff UX/UI Designer & Developer
                                    </span>
                                    <span className="white fw-700 font-sm pt-1">
                                        CastleBranch
                                    </span>
                                </div>
                                <div className="flex-r mr-lg-5 my-4 my-lg-0">
                                    <i className="fa-solid fa-users-gear font-lg g-700"></i>
                                    <i className="fa-solid fa-swatchbook font-lg green mx-4"></i>
                                    <i className="fa-solid fa-code-merge font-lg g-700"></i>
                                </div>
                                <div className="green font-sm ml-lg-5 mt-1">
                                    07/16 - 02/17
                                </div>
                            </div>
                            <div className="flex-c flex-lg-r job">
                                <div className="flex-c flex-1 text-c text-md-l align-items-center align-items-lg-start">
                                    <span className="white font-lg mb-1 mb-lg-0">
                                        Senior UX/UI Designer & Developer
                                    </span>
                                    <span className="white fw-700 font-sm pt-1">
                                        Ippon Technologies USA
                                    </span>
                                </div>
                                <div className="flex-r mr-lg-5 my-4 my-lg-0">
                                    <i className="fa-solid fa-users-gear font-lg blue"></i>
                                    <i className="fa-solid fa-swatchbook font-lg green mx-4"></i>
                                    <i className="fa-solid fa-code-merge font-lg g-700"></i>
                                </div>
                                <div className="green font-sm ml-lg-5 mt-1">
                                    10/15 - 07/16
                                </div>
                            </div>
                            <div className="flex-c flex-lg-r job">
                                <div className="flex-c flex-1 text-c text-md-l align-items-center align-items-lg-start">
                                    <span className="white font-lg mb-1 mb-lg-0">
                                        Senior UX/UI Engineer
                                    </span>
                                    <span className="white fw-700 font-sm pt-1">
                                        WealthForge
                                    </span>
                                </div>
                                <div className="flex-r mr-lg-5 my-4 my-lg-0">
                                    <i className="fa-solid fa-users-gear font-lg blue"></i>
                                    <i className="fa-solid fa-swatchbook font-lg green mx-4"></i>
                                    <i className="fa-solid fa-code-merge font-lg red"></i>
                                </div>
                                <div className="green font-sm ml-lg-5 mt-1">
                                    05/14 - 10/15
                                </div>
                            </div>
                            <div className="flex-c flex-lg-r job">
                                <div className="flex-c flex-1 text-c text-md-l align-items-center align-items-lg-start">
                                    <span className="white font-lg mb-1 mb-lg-0">
                                        UX/UI Designer & Developer
                                    </span>
                                    <span className="white fw-700 font-sm pt-1">
                                        Alynnlee Productions
                                    </span>
                                </div>
                                <div className="flex-r mr-lg-5 my-4 my-lg-0">
                                    <i className="fa-solid fa-users-gear font-lg g-700"></i>
                                    <i className="fa-solid fa-swatchbook font-lg green mx-4"></i>
                                    <i className="fa-solid fa-code-merge font-lg red"></i>
                                </div>
                                <div className="green font-sm ml-lg-5 mt-1">
                                    07/13 - 05/14
                                </div>
                            </div>
                            <div className="flex-c flex-lg-r job">
                                <div className="flex-c flex-1 text-c text-md-l align-items-center align-items-lg-start">
                                    <span className="white font-lg mb-1 mb-lg-0">
                                        UX/UI Designer & Developer
                                    </span>
                                    <span className="white fw-700 font-sm pt-1">
                                        NetSearch Digital Marketing
                                    </span>
                                </div>
                                <div className="flex-r mr-lg-5 my-4 my-lg-0">
                                    <i className="fa-solid fa-users-gear font-lg g-700"></i>
                                    <i className="fa-solid fa-swatchbook font-lg green mx-4"></i>
                                    <i className="fa-solid fa-code-merge font-lg red"></i>
                                </div>
                                <div className="green font-sm ml-lg-5 mt-1">
                                    08/12 - 07/13
                                </div>
                            </div>
                            <div className="flex-c flex-lg-r job">
                                <div className="flex-c flex-1 text-c text-md-l align-items-center align-items-lg-start">
                                    <span className="white font-lg mb-1 mb-lg-0">
                                        Junior UX/UI Designer & Developer
                                    </span>
                                    <span className="white fw-700 font-sm pt-1">
                                        The Idea Center
                                    </span>
                                </div>
                                <div className="flex-r mr-lg-5 my-4 my-lg-0">
                                    <i className="fa-solid fa-users-gear font-lg g-700"></i>
                                    <i className="fa-solid fa-swatchbook font-lg green mx-4"></i>
                                    <i className="fa-solid fa-code-merge font-lg red"></i>
                                </div>
                                <div className="green font-sm ml-lg-5 mt-1">
                                    04/12 - 08/12
                                </div>
                            </div>

                            <div className="flex-c flex-lg-r my-5">
                                <div className="flex-c sch-icon bck-green">
                                    <i className="fa-solid fa-graduation-cap black"></i>
                                </div>
                                <div className="flex-c flex-1 text-c text-md-l flex-lg-r job school align-items-lg-center mt-0">
                                    <div className="flex-c flex-1">
                                        <span className="white font-lg mb-3 mb-sm-1 mb-md-0">
                                            Pennsylvania College of Technology
                                        </span>
                                        <span className="white fw-700 font-sm pt-1">
                                            Bachelor of Science: Graphic Design
                                        </span>
                                    </div>
                                    <div className="green font-sm ml-lg-5 mt-4 mt-lg-0">
                                        08/07 - 05/11
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {modalValue && (
                <WidgetModal
                    showModal={setModalValue}
                    title={titleValue}
                    body={bodyValue}
                    icon={iconValue}
                />
            )}
        </>
    )
}

export default Home
