import React from 'react'
import case1 from '../img/case-4/case4.1-min.png'
import case2 from '../img/case-4/case4.2-min.png'
import case3 from '../img/case-4/case4.3-min.png'
import case4 from '../img/case-4/case4.4-min.png'
import case5 from '../img/case-4/case4.5-min.png'

function Case4() {
    return (
        <div className="flex-c bck-white pb-5">
            <div className="flex-c container nav-offset mb-5">
                <div className="flex-c row mb-5">
                    <div className="flex-c col-12">
                        <h1 className="mb-0">Prototype</h1>
                        <p>
                            A steps counter Designed to help raise funds and
                            awareness for various causes.
                        </p>
                    </div>
                </div>
                <div className="flex-c flex-md-r flex-wrap row mb-5">
                    <div className="flex-c col-12 col-lg-6 col-xl-3 mb-4 mb-xl-0">
                        <span className="font-md fw-700">Role</span>
                        <p>UX/UI Designer</p>
                    </div>
                    <div className="flex-c col-12 col-lg-6 col-xl-3 mb-4 mb-xl-0">
                        <span className="font-md fw-700">Team</span>
                        <p>Project Manager &amp; Designer</p>
                    </div>
                    <div className="flex-c col-12 col-lg-6 col-xl-3 mb-4 mb-xl-0">
                        <span className="font-md fw-700">Tools</span>
                        <p>Sketch, Invision</p>
                    </div>
                    <div className="flex-c col-12 col-lg-6 col-xl-3 mb-4 mb-xl-0">
                        <span className="font-md fw-700">Timeline</span>
                        <p>05/22 - 05/22</p>
                    </div>
                </div>
                <div className="flex-c row mb-5">
                    <div className="flex-c col-12">
                        <span className="font-xl fw-700 mb-2">
                            Understanding the Problem
                        </span>
                        <p className="mb-3">
                            To record steps of individuals on teams in order to
                            raise funds and awareness for various causes. There
                            is no means of adaquately recording steps and
                            aggregating the data in a readily available
                            location.
                        </p>
                        <p>
                            Per an in office poll, we confirmed the potential
                            for an app, but we had no idea what or how the app
                            would be recieved. So, we decided to build a
                            prototype to gauge feedback.
                        </p>
                    </div>
                </div>
                <div className="flex-c row mb-5">
                    <div className="flex-c col-12">
                        <span className="font-xl fw-700 mb-2">
                            The Solution
                        </span>
                        <p>
                            Build a prototype of an app that provides a solution
                            to our problem: counts steps of every user, on every
                            team and is readily available. Then, share the
                            prototype and collect feedback to determine if the
                            app is worth building.
                        </p>
                    </div>
                </div>
                <div className="flex-c row mb-5">
                    <div className="flex-c col-12">
                        <span className="font-xl fw-700 mb-2">The Process</span>
                        <p className="mb-3">
                            First we'll outline what the app needs to do and
                            speculate on how robust we might want the app to be.
                            We need the app to do a very simple set of tasks,
                            but there may be future feature requests so we
                            should try to account for a few of the larger, more
                            obvious, requests. From there, we'll trim down to a
                            core set of features and start on the design for
                            testing.
                        </p>
                        <ul>
                            <li>
                                Initiate an open forum, allowing the team to ask
                                many questions
                            </li>
                            <li>
                                Freely provide any number of answers to the
                                defined quetsions to further define what the app
                                shoud really do
                            </li>
                            <li>
                                Outline what the core features are and what they
                                need to do, end to end
                            </li>
                            <li>
                                Map out a few blue sky features, maybe we
                                include these into the prototype to gauge
                                feedback for the MVP
                            </li>
                            <li>
                                Determine how the app will be onboarded and
                                create a user journey defining all potential
                                paths
                            </li>
                            <li>
                                Have a brief kickoff session with development to
                                determine what technology would be required to
                                accommodate our needs
                            </li>
                            <li>Select a few features to start wireframing</li>
                            <li>
                                Process and interate on those wireframes working
                                them into low-fidelity mocks
                            </li>
                            <li>
                                Gauge feedback from a select group in house,
                                iterate and refine existing mocks into high
                                fidelty
                            </li>
                            <li>
                                Keep design systems in mind: we won't initially
                                build one, but we will need one if the app is
                                built
                            </li>
                            <li>
                                Continue to iterate on both the UX and UI:
                                satisfy the app requirements as efficiently as
                                possible
                            </li>
                            <li>
                                Build a simple prototype and share it with the
                                team for feedback
                            </li>
                            <li>
                                Aggregate feedback and determine if the app should be built
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="flex-c row mb-4">
                    <div className="flex-c col-12">
                        <span className="font-xl fw-700">Brainstorming</span>
                        <div className="divider py-2" />
                        <img
                            src={case1}
                            title="Prototype Brainstorming"
                            alt="Prototype Brainstorming"
                        />
                    </div>
                </div>
                <div className="flex-c row mb-4">
                    <div className="flex-c col-12">
                        <span className="font-xl fw-700">
                            Feature Exploration
                        </span>
                        <div className="divider py-2" />
                        <img
                            src={case2}
                            title="Prototype Exploration"
                            alt="Prototype Exploration"
                        />
                    </div>
                </div>
                <div className="flex-c row mb-4">
                    <div className="flex-c col-12">
                        <span className="font-xl fw-700">User Journey</span>
                        <div className="divider py-2" />
                        <img
                            src={case3}
                            title="Prototype User Journey"
                            alt="Prototype User Journey"
                        />
                    </div>
                </div>
                <div className="flex-c row mb-4">
                    <div className="flex-c col-12">
                        <span className="font-xl fw-700">Wireframes</span>
                        <div className="divider py-2" />
                        <img
                            src={case4}
                            title="Prototype Wireframes"
                            alt="Prototype Wireframes"
                        />
                    </div>
                </div>
                <div className="flex-c row mb-5">
                    <div className="flex-c col-12">
                        <span className="font-xl fw-700 mb-2">
                            What We Learned
                        </span>
                        <p>
                            The prototype was determined to be very simple, but effective in demonstrating what the app could look like and how it might function. There was enough here to either create excitement in favor of the app or to decide against the investment. While the prototype was simple, the basic idea was captured as efficiently as possible. The UX and UI of the app were well recieved and the general experience was reported to be easy and straight forward.
                        </p>
                    </div>
                </div>
                <div className="flex-c row my-5">
                    <div className="flex-c col-12">
                        <span className="font-xl fw-700">Visual Design</span>
                        <div className="divider py-2" />
                        <img
                            src={case5}
                            title="Prototype Interface"
                            alt="Prototype Interface"
                        />
                    </div>
                </div>
                <div className="flex-c row mb-5">
                    <div className="flex-c col-12">
                        <span className="font-xl fw-700 mb-2">
                            Results &amp; Insights
                        </span>
                        <p className="mb-3">
                            Ultimately, this app was never built. A perfect example of how simple UI kits and basic prototypes can help to determine if it's worth investing in building an app. The user feedback was great, everyone was excited and enjoyed interacting with the app. The issue was the ROI for creating a custom built app didn't make much sense.
                        </p>
                        <p>
                            We opted to record steps with existing technology and manually aggregate team progress in house. While this was not as fun as building or using a custom app; the design project was successful. We set out to determine if the app should be built and luckily we were able to determine we should not continue. Fortunately, we found our answer without having too much time or energy invested into the project.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Case4
