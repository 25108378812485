import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { userPass } from '../config/slice/auth.slice'
import { useForm } from 'react-hook-form'
import firewall from '../img/Firewall-min.png'
import { modalView } from '../config/slice/modal.slice'
import fingers from '../img/Fingers-min.png'

function Firewall() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [modalValue, setModalValue] = useState(false)

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        })
    }, [])

    const { handleSubmit } = useForm()

    const onSubmit = () => {
        const answer = document.getElementById('password').value

        if (answer === 'c137') {
            dispatch(userPass(true))
            navigate('/')
        } else {
            dispatch(modalView(true))
            setModalValue(true)
        }
    }

    return (
        <>
            <div className="flex-c firewall">
                <div className="flex-c nav-offset container">
                    <div className="flex-c row align-items-center text-c">
                        <div className="flex-c px-3">
                            <h1 className="white font-xl mb-2 mt-5">
                                Password Required
                            </h1>
                            <span className="white text-c fw-600 font-sm mb-5">
                                Enter the password to continue.
                            </span>
                        </div>
                        <div className="flex-c col-12 col-sm-8 col-md-6 col-lg-4 align-items-center justify-content-center">
                            <form className="flex-r mb-5" onSubmit={onSubmit}>
                                <input
                                    className="form-control b-radius-reset-r"
                                    autoCapitalize="none"
                                    id="password"
                                    name="password"
                                />
                                <button
                                    className="btn btn-p border-none b-radius-reset-l"
                                    onClick={handleSubmit(onSubmit)}>
                                    Let's Go!
                                </button>
                            </form>
                            <img
                                className="mt-2"
                                src={firewall}
                                title="Enter Password"
                                alt="Enter Password"
                            />
                        </div>
                    </div>
                </div>
            </div>
            {modalValue && (
                <div
                    className="modal-background"
                    role="dialog"
                    onClick={() => {
                        dispatch(modalView(false))
                        setModalValue(false)
                    }}>
                    <div
                        className="col-sm-8 col-md-6 col-lg-3"
                        role="document"
                        onClick={e => e.stopPropagation()}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className="flex-c flex-1 align-items-end">
                                    <i
                                        className="fa-solid fa-xmark gray-800"
                                        onClick={() => {
                                            dispatch(modalView(false))
                                            setModalValue(false)
                                        }}
                                        title="Close Modal"></i>
                                </div>
                            </div>
                            <img
                                className="fingers"
                                src={fingers}
                                title="Wrong Password"
                                alt="Wrong Password"
                            />
                            <div className="flex-c text-c modal-footer"></div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default Firewall
